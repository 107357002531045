exports.components = {
  "component---1789430816-4-09-19-forritunarkeppni-haskolanna-a-islandi-2024-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2024-09-19-forritunarkeppni-haskolanna-a-islandi-2024/index.mdx" /* webpackChunkName: "component---1789430816-4-09-19-forritunarkeppni-haskolanna-a-islandi-2024-index-mdx" */),
  "component---1827086007-nt-posts-2023-07-24-evropsku-olympiuleikar-stelpna-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2023-07-24-evropsku-olympiuleikar-stelpna/index.mdx" /* webpackChunkName: "component---1827086007-nt-posts-2023-07-24-evropsku-olympiuleikar-stelpna-index-mdx" */),
  "component---186313449-nt-posts-2019-09-25-haskolakeppnin-i-forritun-2019-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2019-09-25-haskolakeppnin-i-forritun-2019/index.mdx" /* webpackChunkName: "component---186313449-nt-posts-2019-09-25-haskolakeppnin-i-forritun-2019-index-mdx" */),
  "component---2059442871--posts-2023-05-30-nordurlandaolympiuleikarnir-2023-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2023-05-30-nordurlandaolympiuleikarnir-2023/index.mdx" /* webpackChunkName: "component---2059442871--posts-2023-05-30-nordurlandaolympiuleikarnir-2023-index-mdx" */),
  "component---2463097468-4-23-island-a-baltnesku-olympiuleikunum-i-forritun-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2017-04-23-island-a-baltnesku-olympiuleikunum-i-forritun/index.mdx" /* webpackChunkName: "component---2463097468-4-23-island-a-baltnesku-olympiuleikunum-i-forritun-index-mdx" */),
  "component---2583714048-nt-posts-2017-09-22-haskolakeppnin-i-forritun-2017-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2017-09-22-haskolakeppnin-i-forritun-2017/index.mdx" /* webpackChunkName: "component---2583714048-nt-posts-2017-09-22-haskolakeppnin-i-forritun-2017-index-mdx" */),
  "component---269172905-posts-2023-09-08-althjodlegu-olympiuleikarnir-2023-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2023-09-08-althjodlegu-olympiuleikarnir-2023/index.mdx" /* webpackChunkName: "component---269172905-posts-2023-09-08-althjodlegu-olympiuleikarnir-2023-index-mdx" */),
  "component---3006536927-s-2019-09-23-forritunarkeppni-haskola-islands-2019-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2019-09-23-forritunarkeppni-haskola-islands-2019/index.mdx" /* webpackChunkName: "component---3006536927-s-2019-09-23-forritunarkeppni-haskola-islands-2019-index-mdx" */),
  "component---3023688251--posts-2024-05-10-eystrasaltsolympiuleikarnir-2024-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2024-05-10-eystrasaltsolympiuleikarnir-2024/index.mdx" /* webpackChunkName: "component---3023688251--posts-2024-05-10-eystrasaltsolympiuleikarnir-2024-index-mdx" */),
  "component---3186768985--posts-2023-07-01-eystrasaltsolympiuleikarnir-2023-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2023-07-01-eystrasaltsolympiuleikarnir-2023/index.mdx" /* webpackChunkName: "component---3186768985--posts-2023-07-01-eystrasaltsolympiuleikarnir-2023-index-mdx" */),
  "component---3314843269-2019-03-23-forritunarkeppni-framhaldsskolanna-2019-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2019-03-23-forritunarkeppni-framhaldsskolanna-2019/index.mdx" /* webpackChunkName: "component---3314843269-2019-03-23-forritunarkeppni-framhaldsskolanna-2019-index-mdx" */),
  "component---3404644663-2020-03-21-forritunarkeppni-framhaldsskolanna-2020-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2020-03-21-forritunarkeppni-framhaldsskolanna-2020/index.mdx" /* webpackChunkName: "component---3404644663-2020-03-21-forritunarkeppni-framhaldsskolanna-2020-index-mdx" */),
  "component---3411042770-2024-03-20-forritunarkeppni-framhaldsskolanna-2024-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2024-03-20-forritunarkeppni-framhaldsskolanna-2024/index.mdx" /* webpackChunkName: "component---3411042770-2024-03-20-forritunarkeppni-framhaldsskolanna-2024-index-mdx" */),
  "component---3445286123-sts-2024-07-30-evropsku-olympiuleikar-stelpna-2024-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2024-07-30-evropsku-olympiuleikar-stelpna-2024/index.mdx" /* webpackChunkName: "component---3445286123-sts-2024-07-30-evropsku-olympiuleikar-stelpna-2024-index-mdx" */),
  "component---3485031652-tent-posts-2019-06-12-vorkeppni-hr-i-forritun-2019-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2019-06-12-vorkeppni-hr-i-forritun-2019/index.mdx" /* webpackChunkName: "component---3485031652-tent-posts-2019-06-12-vorkeppni-hr-i-forritun-2019-index-mdx" */),
  "component---3723094656-3-09-10-forritunarkeppni-haskolanna-a-islandi-2023-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2023-09-10-forritunarkeppni-haskolanna-a-islandi-2023/index.mdx" /* webpackChunkName: "component---3723094656-3-09-10-forritunarkeppni-haskolanna-a-islandi-2023-index-mdx" */),
  "component---4194554613-2023-04-23-forritunarkeppni-framhaldsskolanna-2023-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2023-04-23-forritunarkeppni-framhaldsskolanna-2023/index.mdx" /* webpackChunkName: "component---4194554613-2023-04-23-forritunarkeppni-framhaldsskolanna-2023-index-mdx" */),
  "component---420784593-2021-05-10-forritunarkeppni-framhaldsskolanna-2021-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2021-05-10-forritunarkeppni-framhaldsskolanna-2021/index.mdx" /* webpackChunkName: "component---420784593-2021-05-10-forritunarkeppni-framhaldsskolanna-2021-index-mdx" */),
  "component---510743196-2022-03-20-forritunarkeppni-framhaldsskolanna-2022-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2022-03-20-forritunarkeppni-framhaldsskolanna-2022/index.mdx" /* webpackChunkName: "component---510743196-2022-03-20-forritunarkeppni-framhaldsskolanna-2022-index-mdx" */),
  "component---60813055-posts-2024-09-19-althjodlegu-olympiuleikarnir-2024-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2024-09-19-althjodlegu-olympiuleikarnir-2024/index.mdx" /* webpackChunkName: "component---60813055-posts-2024-09-19-althjodlegu-olympiuleikarnir-2024-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/blog-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-ad-leysa-thrautir-med-forritun-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/pages/ad-leysa-thrautir-med-forritun/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-ad-leysa-thrautir-med-forritun-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-dagatal-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/pages/dagatal/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-dagatal-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-fb-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/pages/fb/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-fb-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-felagsadild-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/pages/felagsadild/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-felagsadild-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-keppnir-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/pages/keppnir/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-keppnir-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-samskiptavettvangar-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/pages/samskiptavettvangar/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-samskiptavettvangar-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-um-felagid-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/pages/um-felagid/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-pages-um-felagid-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2017-03-25-stofnfundur-haldinn-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2017-03-25-stofnfundur-haldinn/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2017-03-25-stofnfundur-haldinn-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2017-10-18-adalfundur-2017-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2017-10-18-adalfundur-2017/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2017-10-18-adalfundur-2017-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2018-10-01-ncpc-2018-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2018-10-01-ncpc-2018/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2018-10-01-ncpc-2018-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2019-01-23-adalfundur-2018-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2019-01-23-adalfundur-2018/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2019-01-23-adalfundur-2018-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2019-05-22-stelpur-og-taekni-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2019-05-22-stelpur-og-taekni/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2019-05-22-stelpur-og-taekni-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2019-12-27-adalfundur-2019-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2019-12-27-adalfundur-2019/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2019-12-27-adalfundur-2019-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2021-04-18-adalfundur-2021-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2021-04-18-adalfundur-2021/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2021-04-18-adalfundur-2021-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2022-05-15-adalfundur-2022-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2022-05-15-adalfundur-2022/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2022-05-15-adalfundur-2022-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2023-04-13-adalfundur-2023-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2023-04-13-adalfundur-2023/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2023-04-13-adalfundur-2023-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2023-12-14-adalfundur-2024-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2023-12-14-adalfundur-2024/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2023-12-14-adalfundur-2024-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2024-09-09-adalfundur-2024-2025-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/keppnisforritun.is/keppnisforritun.is/content/posts/2024-09-09-adalfundur-2024---2025/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-home-runner-work-keppnisforritun-is-keppnisforritun-is-content-posts-2024-09-09-adalfundur-2024-2025-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tag-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tags-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

